import { RoadMap } from "../model";
import { SORTED_CHANGETYPES } from "./constants";

export const getSlashedTargetRelease = (key: string, option: string) => (key === "targetRelease" && option.length > 4) ? option.slice(0, 4) + "/" + option.slice(4) : option

export const convertToCamelCase = (str: string) => {
    const scopeIndex = str.indexOf("scope");
    const descriptorIndex = str.indexOf("descriptor");
    if (scopeIndex > 0) {
        const substring = str.substring(0, scopeIndex);
        let convertedString = substring.toUpperCase();

        if (convertedString.length > 4) {
            convertedString =
                convertedString.substring(0, 4) + "/" + convertedString.substring(4);
        }
        return convertedString + " Scope :";
    } else if (descriptorIndex > 0) {
        const substring = str.substring(0, descriptorIndex);
        let convertedString = substring.toUpperCase();
        if (convertedString.length > 4) {
            convertedString =
                convertedString.substring(0, 4) + "/" + convertedString.substring(4);
        }
        return convertedString + " Descriptor :";
    }

    return str;
};

export const getFilterArrayByKeys = (
    RoadMaps: RoadMap[],
    keys: string[],
    currentFilter: string,
    filtersList: any
) => {
    const filters: any = {};

    keys.forEach((key) => {
        filters[key] = RoadMaps.map((value: any) => value[key])
            .filter((value: any, index: number, array: any) => !!value && array.indexOf(value) === index)
            .sort((a, b) => a.localeCompare(b));

        if (currentFilter === key) {
            filters[key] = filtersList[key]
        }
    });

    filters.changeType = filters.changeType.sort((a: string, b: string) => {
        const aIndex = SORTED_CHANGETYPES.indexOf(a);
        const bIndex = SORTED_CHANGETYPES.indexOf(b);
        return aIndex - bIndex;
    });
    return filters;
};

function agentHas(keyword: string) {
    return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

export function isEdge() {
    return agentHas("Edg");
}

export function extractItemIdFromString(str: string): string {
    try {
        // Trim the string to handle any leading or trailing whitespace
        const trimmedStr = str.trim();

        // Return blank if the string is empty
        if (trimmedStr === '') {
            return '';
        }

        // Handle URLs to extract the ID
        if (trimmedStr.startsWith('https://')) {
            const match = trimmedStr.match(/\/(\d+)(?:\/|$)/);
            return match ? match[1] : '';
        }

        // Regular expression to match numeric IDs in the context of item types with optional colon and spaces
        const regexWithColon = /(?:Feature|User Story|Epic|Initiative)\s*(\d+)(?:[:\s]|$)/i;

        // Extract numeric ID from descriptive text with item types
        const matchWithColon = trimmedStr.match(regexWithColon);
        if (matchWithColon) {
            return matchWithColon[1];
        }

        // Handle cases where ID is directly attached to item type without space or colon
        const directIdRegex = /(?:Feature|User Story|Epic|Initiative)(\d+)[^\d\s]/i;
        const directIdMatch = trimmedStr.match(directIdRegex);
        if (directIdMatch) {
            return directIdMatch[1];
        }

        // Handle cases where ID is directly attached to item type with trailing non-digit characters
        const directIdWithTrailingCharsRegex = /(?:Feature|User Story|Epic|Initiative)\s*(\d+)(?=[^\d\s]|$)/i;
        const directIdWithTrailingCharsMatch = trimmedStr.match(directIdWithTrailingCharsRegex);
        if (directIdWithTrailingCharsMatch) {
            return directIdWithTrailingCharsMatch[1];
        }

        // Return blank if no ID is found
        return '';
    } catch (error) {
        return '';
    }
}
