import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { BrowserCacheLocation, Configuration, PublicClientApplication } from '@azure/msal-browser';

import Home from './Home';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthConfig } from './model';
import { AUTHENTICATION } from './common/constants';
import './index.css';

const msalConfiguration: Configuration = {
  auth: {
    clientId: AuthConfig.ClientId,
    authority: AuthConfig.Authority,
    redirectUri: AuthConfig.RedirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage
  }
};

const pcaInstance = new PublicClientApplication(msalConfiguration);

async function initializeLoginProcess() {
  await pcaInstance.initialize();

  pcaInstance.handleRedirectPromise().then((response) => {
    if (response !== null) {
      pcaInstance.setActiveAccount(response.account);
    } else {
      const currentAccounts = pcaInstance.getAllAccounts();
      if (currentAccounts.length === 0) {
        pcaInstance.loginRedirect();
      } else if (currentAccounts.length >= 1) {
        pcaInstance.setActiveAccount(currentAccounts[0]);
      }
    }
  }).catch(err => {
    console.error(err);
  });
}

if (AUTHENTICATION) {
  initializeLoginProcess();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <FluentProvider theme={webLightTheme}>
      {AUTHENTICATION ? <MsalProvider instance={pcaInstance}>
        <App />
      </MsalProvider> : <Home />}
    </FluentProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
