export const FILTERS_ARRAY: { fKey: string, fLabel: string }[] = [{
    fKey: "investmentCategory",
    fLabel: "Investment Category"
},
{
    fKey: "initiativeName",
    fLabel: "Initiative Name"
},
{
    fKey: "solutionArea",
    fLabel: "Solution Area"
},
{
    fKey: "workloadScenario",
    fLabel: "Workload"
},
{
    fKey: "engPillar",
    fLabel: "Engineering Pillar"
},
{
    fKey: "ai",
    fLabel: "AI Flag"
},
{
    fKey: "changeType",
    fLabel: "Change Type"
},
{
    fKey: "targetRelease",
    fLabel: "Target Release"
}]

export const DEFAULT_FILTERS = {
    investmentCategory: [],
    initiativeName: [],
    solutionArea: [],
    workloadScenario: [],
    engPillar: [],
    ai: [],
    changeType: [],
    targetRelease: [],
};

export const THEMES = {
    Primary: {
        mainHeader: "#000",
        header: "#3f827a",
        roadmapItem: "#EEEd",
        targetRelease: "#cfdfdd",
    },
    Secondary: {
        mainHeader: "#7971ea",
        header: "#8e98f5",
        roadmapItem: "#dfe2fe",
        targetRelease: "#b1cbfa",
    },
    Tertiary: {
        mainHeader: "#465c73",
        header: "#c86b2d",
        roadmapItem: "#b5bdc4",
        targetRelease: "#fbeebf",
    },
}

export const AI_FLAG = { F: "Feature", P: "Product" };
export const EXCLUDED_SCOPES = ["Scope:TBC", "Scope TBC", "N/A", null, ""];
export const AUTHENTICATION = true;
export const SORTED_CHANGETYPES = ["New", "Expand", "Maintain", "Reduce"];
export const TARGET_RELEASE_TOBE_REMOVED = ['FY24Q3', 'FY24Q4', 'FY25H1', 'FY25H2'];
export const DEFAULT_COLUMNS_IN_PRESENTATION_VIEW = ['FY25Q1', 'FY25Q2', 'FY25Q3', 'FY25Q4'];
export const HEADER_VIDEO_LINK = "https://aka.ms/FTEngRoadmapVideo";