import { useMemo } from "react";
import { Dropdown, Option, OptionOnSelectData, SelectionEvents } from "@fluentui/react-combobox";

const Sort = ({ sortingOrder, onSort }: { sortingOrder: string, onSort: (value: string) => void }) => useMemo(() => <div style={{
    display: "flex",
    alignItems: "center"
}}>
    <Dropdown
        onOptionSelect={(
            event: SelectionEvents,
            data: OptionOnSelectData
        ) => {
            onSort(data.optionValue || "A-Z")
        }}
        placeholder="Sort By"
        selectedOptions={[sortingOrder]}
        defaultSelectedOptions={[sortingOrder]}
        style={{ minWidth: 150 }}
    >
        <Option key="AZ" text="A-Z" value="A-Z" defaultChecked>
            A-Z
        </Option>
        <Option key="ZA" text="Z-A" value="Z-A">
            Z-A
        </Option>
    </Dropdown>
</div>, [sortingOrder])

export default Sort;
