import React from "react";
import { Dropdown, Option } from "@fluentui/react-components";

import { DEFAULT_COLUMNS_IN_PRESENTATION_VIEW } from "../common/constants";
import { getSlashedTargetRelease } from "../common/utils";
import { ColumnSelectorProps } from "../common/types";

const ColumnSelector = ({ targetReleaseFilters, onClickColumn, selectedColumns }: ColumnSelectorProps) => {

    const isDisabled = (option: string) => selectedColumns.length === 4 && selectedColumns.map(o => o.replace("/", '')).includes(option)

    return <Dropdown
        multiselect
        button={<span className="filter-header" title="Minimum of 4 columns must be selected">
            {"Choose columns"}
        </span>}
        positioning={"below-start"}
        placeholder={"Choose columns"}
        inlinePopup
        style={{ minWidth: 'fit-content' }}
        onOptionSelect={(ev, data) => onClickColumn(ev, data.selectedOptions)}
        selectedOptions={selectedColumns}
    >
        {targetReleaseFilters.map((option: string) => (
            <Option key={option} disabled={isDisabled(option)}>
                {getSlashedTargetRelease('targetRelease', option)}
            </Option>
        ))}
    </Dropdown>
};

export default ColumnSelector;
