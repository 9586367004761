import React, { SyntheticEvent } from "react";
import { Accordion, Checkbox, SearchBox, Spinner, Switch } from "@fluentui/react-components";
import { withMsal } from "@azure/msal-react";

import { getRoadMap } from "./api";
import { AuthConfig, RoadMap, RoadMapList } from "./model";
import Filters from "./components/Filters";
import AccordianWrapper from "./components/AccordianWrapper";
import { EXCLUDED_SCOPES, AI_FLAG, DEFAULT_FILTERS, AUTHENTICATION, TARGET_RELEASE_TOBE_REMOVED, DEFAULT_COLUMNS_IN_PRESENTATION_VIEW } from "./common/constants";
import { getFilterArrayByKeys, getSlashedTargetRelease } from "./common/utils";
import { HomeProps, HomeState, currentTheme } from "./common/types";
import Header from "./components/Header";
import Sort from "./components/Sort";
import Pagination from "./components/Pagination";
import MainHeader from "./components/MainHeader";
import ModernUI from "./components/ModernUI";
import ThemeSelector from "./components/ThemeSelector";
import ColumnSelector from "./components/ColumnSelector";

//remove any after AUTHENTICATION constant is removed 
class HomeC extends React.Component<HomeProps | any, HomeState> {
  constructor(props: HomeProps | any) {
    super(props);
    this.state = {
      roadMap: new RoadMapList(),
      allRecords: new RoadMapList(),
      pageNumbers: [],
      currentPage: 1,
      itemsPerPage: 20,
      SearchText: "",
      SortingOrder: "A-Z",
      scopeProperties: [],
      filtersList: DEFAULT_FILTERS,
      checkedFilters: DEFAULT_FILTERS,
      isLoading: false,
      accessToken: "",
      userName: "",
      loginName: "",
      wholeWordMatch: true,
      currentFilter: "",
      showModernUi: false,
      currentTheme: "Primary",
      columns: DEFAULT_COLUMNS_IN_PRESENTATION_VIEW.map(a => a.replace("FY25", "FY25/"))
    };
  }

  async componentDidMount(): Promise<void> {
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    if (isAuthenticated) {
      const userName = this.props.msalContext.accounts[0].name as string;
      const loginName = this.props.msalContext.accounts[0].username as string;
      this.setState({ userName, loginName });

      const accessTokenRequest = {
        scopes: [AuthConfig.Scope],
        account: this.props.msalContext.instance.getAllAccounts()[0],
      };
      await this.props.msalContext.instance.acquireTokenSilent(accessTokenRequest).then((response: any) => this.fetchData(response.accessToken));
    }
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<HomeState>,
    snapshot?: any
  ): void {

    const { checkedFilters, currentFilter } = this.state;

    if (prevState.checkedFilters !== checkedFilters) {
      this.setState({ roadMap: this.state.allRecords });
      const totalNoOfFilters = Object.keys(checkedFilters)
        .map(
          (a) => (checkedFilters as { [key: string]: any })[a].length
        )
        .reduce((a, b) => a + b, 0);

      if (totalNoOfFilters === 0) return;

      const filteredData = this.state.allRecords?.RoadMaps?.filter(
        (item: RoadMap) => {
          if (checkedFilters.investmentCategory.length === 0)
            return true;
          return checkedFilters.investmentCategory.includes(
            item.investmentCategory
          );
        }
      )
        .filter((item) => {
          if (checkedFilters.initiativeName.length === 0)
            return true;
          return checkedFilters.initiativeName.includes(
            item.initiativeName
          );
        })
        .filter((item) => {
          if (checkedFilters.solutionArea.length === 0)
            return true;
          return checkedFilters.solutionArea.includes(
            item.solutionArea
          );
        })
        .filter((item) => {
          if (checkedFilters.workloadScenario.length === 0)
            return true;
          return checkedFilters.workloadScenario.includes(
            item.workloadScenario
          );
        })
        .filter((item) => {
          if (checkedFilters.engPillar.length === 0) return true;
          return checkedFilters.engPillar.includes(item.engPillar);
        })
        .filter((item) => {
          if (checkedFilters.ai.length === 0) return true;
          return checkedFilters.ai.includes(item.ai);
        })
        .filter((item) => {
          if (checkedFilters.changeType.length === 0) return true;
          return checkedFilters.changeType.includes(item.changeType);
        })
        .filter((item) => {
          if (checkedFilters.targetRelease.length === 0) return true;

          const b = Object.keys(item)
            .filter((a) => a.includes("scope"))
            .map((a) =>
              getSlashedTargetRelease("targetRelease", a.replace("scope", "").replace("descriptor", "").toUpperCase())
            );
          return checkedFilters.targetRelease.some(
            (e) =>
              b.includes(e) &&
              !EXCLUDED_SCOPES.includes(
                (item as { [key: string]: any })[e.replace("/", "").toLocaleLowerCase() + "scope"]
              )
          );
        });

      this.resetFilters(filteredData as RoadMap[], currentFilter)

      this.setState({ roadMap: { RoadMaps: filteredData }, currentPage: 1 });
    }
  }

  private fetchData = (authToken: string) => {
    this.setState({ isLoading: true });
    getRoadMap(authToken)
      .then((data) => {
        this.setState({ isLoading: false });
        const roadMapList: RoadMapList = new RoadMapList();
        roadMapList.RoadMaps = data;
        roadMapList.RoadMaps = roadMapList.RoadMaps.filter(
          (item: RoadMap) => {
            TARGET_RELEASE_TOBE_REMOVED.forEach((key) => {
              delete (item as any)[key.toLocaleLowerCase() + 'scope'];
              delete (item as any)[key.toLocaleLowerCase() + 'descriptor'];
            });
            return item.roadmapItemName !== null && item.roadmapItemName !== ""
          }
        ).map((item: RoadMap) => ({ ...item, ai: (AI_FLAG as any)[item.ai] }));

        this.setState({
          roadMap: roadMapList,
          allRecords: roadMapList
        }, () => this.resetFilters(roadMapList.RoadMaps as RoadMap[]));
        const pageNumbers: number[] = [];
        for (
          var i = 1;
          i <= Math.ceil(roadMapList.RoadMaps.length / this.state.itemsPerPage);
          i++
        ) {
          pageNumbers.push(i);
        }
        this.setState({ pageNumbers: pageNumbers });

        if (roadMapList.RoadMaps && roadMapList.RoadMaps.length > 0) {
          const roadMapProperties = Object.keys(
            roadMapList.RoadMaps[0] || {}
          ).filter(
            (property) =>
              property.toLowerCase().includes("scope") ||
              property.toLowerCase().includes("descriptor")
          );
          const allProperties = roadMapProperties
            .map((prop) => this.getTargetReleaseInfo(prop.toLowerCase()))
            ?.sort((a, b) => a.localeCompare(b));
          const sortedRoadMapProperties = Array.from(
            new Set(allProperties.map((item: string) => item.toLowerCase()))
          );
          this.setState({ scopeProperties: sortedRoadMapProperties });
        }
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  private onClickFilter = (ev: SyntheticEvent, data: string[], category: string) => {
    this.setState((prevState) => ({
      currentFilter: category,
      checkedFilters: {
        ...prevState.checkedFilters,
        [category]: data,
      },
    }), () => {
      if (Object.values(this.state.checkedFilters).flat().length === 0) {
        this.resetFilters(this.state.allRecords.RoadMaps as RoadMap[])
      }
    });
  };

  private onClickColumn = (ev: SyntheticEvent, data: string[]) => {
    this.setState({
      columns: data
    });
  };

  private clearAll = () => {
    this.setState({
      checkedFilters: DEFAULT_FILTERS,
    });

    this.resetFilters(this.state.allRecords.RoadMaps as RoadMap[])
  };

  private resetFilters = (data: RoadMap[], currentFilter = "") => {
    const filtersData = getFilterArrayByKeys(data as RoadMap[], [
      "investmentCategory",
      "initiativeName",
      "solutionArea",
      "workloadScenario",
      "engPillar",
      "ai",
      "changeType",
    ], currentFilter, this.state.filtersList);

    const targetReleaseList = Object.keys((this.state.allRecords.RoadMaps as RoadMap[])[0] || [])
      .filter((a) => a.includes("scope"))
      .filter((a) => (this.state.allRecords.RoadMaps as RoadMap[]).find(d => !!(d as any)[a]))
      .map((a) => a.replace("scope", "").toUpperCase())
      .filter((a) => !TARGET_RELEASE_TOBE_REMOVED.includes(a))
      .sort()

    this.setState({
      filtersList: {
        ...filtersData,
        targetRelease: targetReleaseList,
      },
    });
  }

  render() {
    const {
      roadMap,
      currentPage,
      itemsPerPage,
      filtersList,
      checkedFilters,
      isLoading,
      SortingOrder,
      scopeProperties,
      SearchText,
      wholeWordMatch,
      showModernUi,
      currentTheme,
      columns,
      allRecords
    } = this.state;

    const pageNumbers: number[] = [];
    let filterItemsLength: number = 0;

    let filterItems: RoadMap[] = [];
    if (
      roadMap &&
      roadMap.RoadMaps &&
      roadMap.RoadMaps.length >= 0
    ) {
      filterItems = roadMap.RoadMaps;
      if (
        SortingOrder.length > 0 &&
        SortingOrder === "A-Z"
      ) {
        filterItems = filterItems.sort((a, b) => {
          if ((a.initiativeName + a.roadmapItemName) && (b.initiativeName + b.roadmapItemName)) {
            return (a.initiativeName + a.roadmapItemName).localeCompare((b.initiativeName + b.roadmapItemName), "es", {
              sensitivity: "base",
            });
          }
          return 0;
        });
      }

      if (
        SortingOrder.length > 0 &&
        SortingOrder === "Z-A"
      ) {
        filterItems = filterItems.sort((a, b) => {
          if ((a.initiativeName + a.roadmapItemName) && (b.initiativeName + b.roadmapItemName)) {
            return (b.initiativeName + b.roadmapItemName).localeCompare((a.initiativeName + a.roadmapItemName), "es", {
              sensitivity: "base",
            });
          }
          return 0;
        });
      }
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    if (
      roadMap &&
      roadMap.RoadMaps &&
      roadMap.RoadMaps.length >= 0
    ) {
      if (SearchText && SearchText.length > 0) {
        filterItems = roadMap.RoadMaps.filter((item: RoadMap) => {
          if (item) {
            return Object.values(item).find((val) => {
              if (val === null) return false
              if (wholeWordMatch) {
                let newSearchText = SearchText
                if (SearchText.includes('[')) {
                  newSearchText = SearchText.replace(/\[/g, '!');
                }
                return new RegExp(`\\b${newSearchText.trim().toLowerCase()}\\b`).test(val?.toLowerCase())
              }
              return val?.toLowerCase().indexOf(SearchText.trim().toLowerCase()) >= 0
            });
          }
          return item;
        });
      }
      if (filterItems.length < itemsPerPage) {
        pageNumbers.push(1);
      } else {
        for (
          var i = 1;
          i <= Math.ceil(filterItems.length / this.state.itemsPerPage);
          i++
        ) {
          pageNumbers.push(i);
        }
      }
      filterItemsLength = filterItems.length;
      filterItems = filterItems.slice(indexOfFirstItem, indexOfLastItem);
    }

    const allColumns = allRecords.RoadMaps ? Object.keys((allRecords.RoadMaps as RoadMap[])[0])
      .filter((a) => a.includes("scope"))
      .map((a) => a.replace("scope", "").toUpperCase())
      .filter((a) => !TARGET_RELEASE_TOBE_REMOVED.includes(a))
      .sort() : []

    return (
      <div className="container">
        <MainHeader userName={this.state.userName} loginName={this.state.loginName} />
        <Header />
        {isLoading ? (
          <Spinner style={{ marginTop: 20 }} />
        ) : (
          <div className="main-box">
            <div className="filters-wrapper">
              <div className="search">
                <h2>Search for a specific item:</h2>
                <SearchBox
                  style={{ width: 250 }}
                  placeholder="Search"
                  onReset={() => {
                    this.setState({ SearchText: "" });
                  }}
                  onChange={(event) => {
                    this.setState({
                      SearchText: (event.currentTarget as HTMLInputElement)
                        .value,
                    });
                  }}
                />
                <div>
                  <Checkbox style={{ marginLeft: -6 }} label={"Match whole word"} onChange={this.setWholeWordMatch} checked={wholeWordMatch} />
                </div>
              </div>
              <div className="filters">
                <h2>Filter the items below:</h2>
                <Filters
                  onClickFilter={this.onClickFilter}
                  filtersList={filtersList}
                  checkedFilters={checkedFilters}
                  clearAll={this.clearAll}
                  isPresentationView={showModernUi}
                />
              </div>
            </div>
            <div className="count-switch">
              <strong>
                Showing{" "}
                <span style={{ color: "#0278d4" }}>{filterItemsLength}</span>{" "}
                updates:
              </strong>
              <div className="count-switch">
                {showModernUi && <ColumnSelector
                  targetReleaseFilters={[...new Set([...DEFAULT_COLUMNS_IN_PRESENTATION_VIEW, ...allColumns])]}
                  onClickColumn={this.onClickColumn}
                  selectedColumns={columns}
                />}
                &nbsp;
                {showModernUi && <ThemeSelector currentTheme={currentTheme} onSelectTheme={this.onSelectTheme} />}
                <Switch label="Presentation view" onChange={this.setModernUi} checked={showModernUi} />
              </div>
            </div>
          </div>
        )}

        {filterItems.length > 0 ? (
          showModernUi ? <ModernUI checkedFilters={checkedFilters} filterItems={filterItems} currentTheme={currentTheme} columns={columns} /> : <div className="main">
            <div className="main-box">
              <div className="sort-wrapper">
                <h2>Initiative Name - Roadmap Item Name</h2>
                <Sort sortingOrder={SortingOrder} onSort={this.onSort} />
              </div>

              <div className="accordians">
                <Accordion collapsible>
                  {filterItems.map((item: RoadMap, index: number) => {
                    const listObj = this.convertObjectToArray(item);
                    return (
                      <AccordianWrapper
                        key={index}
                        value={index}
                        listObj={listObj}
                        item={item}
                        scopeProperties={scopeProperties}
                        checkedFilters={checkedFilters}
                      />
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        ) : (
          !isLoading && (
            <div style={{ padding: 20, textAlign: "center" }}>
              No data found
            </div>
          )
        )}

        {filterItems.length > 0 && (
          <Pagination
            currentPage={this.state.currentPage}
            handlePrevPageClick={this.handlePrevPageClick}
            pageNumbers={pageNumbers}
            handlePageClick={this.handlePageClick}
            handleNextPageClick={this.handleNextPageClick}
          />
        )}
      </div>
    );
  }

  private onSelectTheme = (theme: currentTheme) => {
    this.setState({
      currentTheme: theme
    })
  }

  private setModernUi = () => {
    this.resetFilters(this.state.allRecords.RoadMaps as RoadMap[])
    this.setState((prevState) => ({
      showModernUi: !prevState.showModernUi,
      checkedFilters: {
        ...prevState.checkedFilters,
        ["targetRelease"]: [],
      },
    }))
  }

  private setWholeWordMatch = () => {
    this.setState((prevState) => ({ wholeWordMatch: !prevState.wholeWordMatch }))
  }

  private onSort = (value: string) => {
    this.setState({ SortingOrder: value as string });
  }

  private getTargetReleaseInfo = (str: string) => {
    const scopeIndex = str.indexOf("scope");
    const descriptorIndex = str.indexOf("descriptor");
    if (scopeIndex > 0) {
      return str.substring(0, scopeIndex).toUpperCase();
    } else if (descriptorIndex > 0) {
      return str.substring(0, descriptorIndex);
    }
    return str;
  };

  private handlePageClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const target = event.target as HTMLAnchorElement;
    const id = target.id;
    this.setState({ currentPage: parseInt(id) });
  };

  private handlePrevPageClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    const currentPage = this.state.currentPage;
    if (this.state.currentPage === 1) {
      return;
    }
    const id = currentPage - 1;
    this.setState({ currentPage: id });
  };

  private handleNextPageClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    const currentPage = this.state.currentPage;
    if (this.state.currentPage === this.state.pageNumbers.length) {
      return;
    }
    const id = currentPage + 1;
    this.setState({ currentPage: id });
  };

  private convertObjectToArray = (object: RoadMap) => {
    const list: { key: string; value: any }[] = [];
    Object.entries(object).forEach((itm) =>
      list.push({ key: itm[0], value: itm[1] })
    );
    return list;
  };
}

const Home = AUTHENTICATION ? withMsal(HomeC) : HomeC;
export default Home;