import React from "react";
import {
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
} from "@fluentui/react-components";

import { convertToCamelCase, getSlashedTargetRelease, extractItemIdFromString } from "../common/utils";
import { EXCLUDED_SCOPES, TARGET_RELEASE_TOBE_REMOVED } from "../common/constants";
import { AccordianProps } from "../common/types";

const AccordianWrapper = ({
    listObj,
    item,
    scopeProperties,
    checkedFilters,
    value,
}: AccordianProps) => {
    const ADOId = extractItemIdFromString(item.adolink);
    let filteredScopeProperties = scopeProperties;
    if (checkedFilters.targetRelease.length > 0) {
        filteredScopeProperties = scopeProperties.filter((a) =>
            checkedFilters.targetRelease.includes(
                getSlashedTargetRelease('targetRelease', a).replace("scope", "").replace("descriptor", "").toUpperCase()
            )
        );
    }

    const isTargetReleaseFilterApplied = checkedFilters.targetRelease.length > 0;

    return (
        <AccordionItem value={value} className="accordian">
            <AccordionHeader>
                <div className="flex align-items-center w-100">
                    <div className="circle">
                        <span className="dark"></span>
                    </div>
                    <span className="accordian-header">
                        {item.initiativeName} - {item.roadmapItemName}
                    </span>
                    <div className="preview">
                        <strong>Target Release: </strong>{" "}
                        {checkedFilters.targetRelease.length === 0
                            ? ["All"]
                            : checkedFilters.targetRelease.join(", ")}
                    </div>
                </div>
            </AccordionHeader>
            <AccordionPanel>
                <div className="accordian-body">
                    <div className="accordian-section">
                        {filteredScopeProperties &&
                            filteredScopeProperties.length > 0 &&
                            filteredScopeProperties.filter((prop: string) => !TARGET_RELEASE_TOBE_REMOVED.includes(prop.toUpperCase())).map((prop: string, index: number) => {
                                const scopePropName = `${prop}scope`;
                                const descriptorPropName = `${prop}descriptor`;
                                const value = listObj.find(
                                    (item) => item.key === descriptorPropName
                                )?.value;
                                return isTargetReleaseFilterApplied && EXCLUDED_SCOPES.includes(value) ? null : (
                                    <React.Fragment key={index}>
                                        <div>
                                            <strong>{convertToCamelCase(scopePropName)} </strong>{" "}
                                            {listObj.find((item) => item.key === scopePropName)?.value}
                                        </div>
                                        <div>
                                            <strong>{convertToCamelCase(descriptorPropName)} </strong>
                                            {
                                                listObj.find((item) => item.key === descriptorPropName)
                                                    ?.value
                                            }
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                    </div>
                    <div style={{
                        borderBottom: "1px solid rgb(232, 232, 232)",
                        marginBottom: 15,
                        marginTop: 15
                    }} />
                    <div className="accordian-section">
                        <div>
                            <strong>Investment Category :</strong> {item.investmentCategory}
                        </div>
                        <div>
                            <strong>Initiative Name :</strong> {item.initiativeName}
                        </div>
                        <div>
                            <strong>Solution Area :</strong> {item.solutionArea}
                        </div>
                        <div>
                            <strong>Workload :</strong> {item.workloadScenario}
                        </div>
                        <div>
                            <strong>Engineering Pillar :</strong> {item.engPillar}
                        </div>
                        <div>
                            <strong>AI Flag :</strong> {item.ai}
                        </div>
                        <div>
                            <strong>Change Type :</strong> {item.changeType}
                        </div>
                        <div>
                            <strong>ADO Link :</strong>{" "}
                            <a
                                href={`https://dev.azure.com/O365fasttrack/FastTrack/_workitems/edit/${ADOId}`}
                                target="_blank"
                            >
                                {item.adolink}
                            </a>
                        </div>
                    </div>

                </div>
            </AccordionPanel>
        </AccordionItem>
    );
};

export default AccordianWrapper;
