import axios from 'axios';
import { RoadMap } from './model';
import { AUTHENTICATION } from './common/constants';

const url = 'https://ftroadmapapi.azurewebsites.net/api/FTERoadMap';
//const url = 'https://localhost:7050/api/FTERoadMap';

export async function getRoadMap(authToken: string): Promise<RoadMap[]> {
  try {
    const config = {
      headers: { Authorization: `Bearer ${authToken}` }
    };
    const response = AUTHENTICATION ? await axios.get(url, config) : await axios.get(url);

    return response.data;
  } catch (error) {
    return [];
  }
}