export class RoadMap {
    public investmentCategory!: string;
    public initiativeName!: string;
    public roadmapItemName!: string;
    public changeType!: string;
    public workloadScenario!: string;
    public engPillar!: string;
    public featureCrew!: string;
    public roadMapLevel!: string;
    public ai!: string;

    public fy24q3scope!: string | null;
    public fy24q3descriptor!: string | null;
    public fy24q4scope!: string | null;
    public fy24q4descriptor!: string | null;
    public fy25h1scope!: string | null;
    public fy25h1descriptor!: string | null;
    public fy25h2scope!: string | null;
    public fy25h2descriptor!: string | null;
    public fy25q1scope!: string | null;
    public fy25q1descriptor!: string | null;
    public fy25q2scope!: string | null;
    public fy25q2descriptor!: string | null;
    public fy25q3scope!: string | null;
    public fy25q3descriptor!: string | null;
    public fy25q4scope!: string | null;
    public fy25q4descriptor!: string | null;
    public fy26scope!: string | null;
    public fy26descriptor!: string | null;
    public fy27scope!: string | null;
    public fy27descriptor!: string | null;
    public adolink!: string;
    public comments!: string;
    public solutionArea!: string;
}

export class RoadMapList {
    public RoadMaps?: RoadMap[];
}

export enum AuthConfig {
    ClientId = '045fb010-889c-4ea0-9e85-a13eeba68429',
    Authority = 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
    RedirectUri = 'https://ftengineeringroadmap.microsoft.com/',
    Scope = 'api://dd34f04f-3e17-4a27-ba09-0566e82cd25b/ftrmp.read'
}




