import {
    Button,
    Option,
    Dropdown,
    makeStyles,
} from "@fluentui/react-components";
import { getSlashedTargetRelease, isEdge } from "../common/utils";
import { FILTERS_ARRAY } from "../common/constants";
import { CustomFilterProps, DropdownWrapperProps } from "../common/types";

const useStyles = makeStyles({
    listbox: {
        maxHeight: "400px",
        transform: "translate(0px, 31px) !important"
    },
});

const DropdownWrapper = ({ checkedFilters, filtersList, onClickFilter, fKey, fLabel, isPresentationView, ...props }: DropdownWrapperProps) => {
    let thisFilterArray = (checkedFilters as { [key: string]: any })[fKey];

    const styles = useStyles();
    return <Dropdown
        disabled={isPresentationView && fKey === 'targetRelease'}
        multiselect
        listbox={{ className: styles.listbox }}
        button={<span style={{ color: thisFilterArray.length > 0 ? "white" : "" }}>
            {fLabel}
        </span>}
        positioning={"below-start"}
        placeholder={fLabel}
        inlinePopup
        style={{ minWidth: 'fit-content' }}
        onOptionSelect={(ev, data) => onClickFilter(ev, data.selectedOptions, fKey)}
        selectedOptions={(checkedFilters as { [key: string]: any })[fKey]}
        className={thisFilterArray.length > 0 ? fKey + " selected" : fKey}
    >
        {props.children}
    </Dropdown>
}

const Filters = ({ onClickFilter, filtersList, checkedFilters, clearAll, isPresentationView }: CustomFilterProps) => (
    <>
        <div style={{ gap: 5 }} className='filters-fields'>
            {FILTERS_ARRAY.slice(0, 4).map((f, i) => (
                <DropdownWrapper
                    key={i}
                    isPresentationView={isPresentationView}
                    checkedFilters={checkedFilters}
                    filtersList={filtersList}
                    onClickFilter={onClickFilter}
                    fKey={f.fKey}
                    fLabel={f.fLabel}
                >
                    {(filtersList as { [key: string]: any })[f.fKey].map((option: any) => (
                        <Option key={option}>
                            {getSlashedTargetRelease(f.fKey, option)}
                        </Option>
                    ))}
                </DropdownWrapper>
            ))}
        </div>
        <div style={{ gap: 5, marginTop: 5 }} className='filters-fields'>
            {FILTERS_ARRAY.slice(4).map((f, i) => (
                <DropdownWrapper
                    key={i}
                    isPresentationView={isPresentationView}
                    checkedFilters={checkedFilters}
                    filtersList={filtersList}
                    onClickFilter={onClickFilter}
                    fKey={f.fKey}
                    fLabel={f.fLabel}
                >
                    {(filtersList as { [key: string]: any })[f.fKey].map((option: any) => (
                        <Option key={option}>
                            {getSlashedTargetRelease(f.fKey, option)}
                        </Option>
                    ))}
                </DropdownWrapper>
            ))}

        <Button onClick={clearAll} style={!isEdge() ? { minWidth: 40 } : {}} className="clear-btn">Clear all</Button>
        </div>
    </>
);

export default Filters;