import React from "react";
import { THEMES } from "../common/constants";
import { FilterTypes, currentTheme } from "../common/types";
import { RoadMap } from "../model";
import { extractItemIdFromString } from "../common/utils";

const ModernUI = ({ checkedFilters, filterItems, currentTheme, columns }: {
    checkedFilters: FilterTypes,
    filterItems: RoadMap[],
    currentTheme: currentTheme,
    columns: string[],
}) => {
    const styles = {
        th: {
            backgroundColor: THEMES[currentTheme].mainHeader,
        },
        triangleA: {
            background: "#FFF",
            position: "absolute",
            right: 0,
            top: 0,
            borderTop: "13px solid transparent",
            borderLeft: `15px solid ${THEMES[currentTheme].mainHeader}`,
            borderBottom: "13px solid transparent"
        } as React.CSSProperties,
        triangleB: {
            background: THEMES[currentTheme].mainHeader,
            position: "absolute",
            left: "-5px",
            top: 0,
            borderTop: "13px solid transparent",
            borderLeft: `15px solid #FFF`,
            borderBottom: "13px solid transparent"
        } as React.CSSProperties,
        header: {
            backgroundColor: THEMES[currentTheme].header,
        },
        roadmapItem: {
            backgroundColor: THEMES[currentTheme].roadmapItem,
            width: columns.length > 4 ? "20%" : "30%"
        },
        targetRelease: {
            backgroundColor: THEMES[currentTheme].targetRelease,
            width: "100px"
        },
    }
    const groupedData: any = {};
    const groupedArray: any = [];

    for (const item of filterItems) {
        const initiativeName = item["initiativeName"];
        if (!groupedData[initiativeName]) {
            groupedData[initiativeName] = [];
        }
        groupedData[initiativeName].push(item);
    }

    for (const initiative in groupedData) {
        groupedArray.push({
            initiativeName: initiative,
            roadmapItems: groupedData[initiative]
        });
    }

    const { investmentCategory } = checkedFilters

    return (<div className="main-box">
        <h3>FASTTRACK ENGINEERING</h3>
        <h1><span className="m-headerSelection"> {investmentCategory.length > 0 && `${investmentCategory.join(", ")}`} </span></h1>
        <table className="table-wrapper">
            <tr className="f-header">
                <th style={styles.th}>Roadmap item</th>
                {columns.includes("FY25/Q1") && <th style={styles.th}>Q1<div style={styles.triangleA}></div></th>}
                {columns.includes("FY25/Q2") && <th style={styles.th}><div style={columns.includes("FY25/Q1") ? styles.triangleB : {}}></div>Q2<div style={styles.triangleA}></div></th>}
                {columns.includes("FY25/Q3") && <th style={styles.th}><div style={columns.includes("FY25/Q1") || columns.includes("FY25/Q2") ? styles.triangleB : {}}></div>Q3<div style={styles.triangleA}></div></th>}
                {columns.includes("FY25/Q4") && <th style={styles.th}><div style={styles.triangleB}></div>Q4<div style={styles.triangleA}></div></th>}
                {columns.includes("FY26") && <th style={styles.th}><div style={styles.triangleB}></div>FY26<div style={styles.triangleA}></div></th>}
                {columns.includes("FY27") && <th style={styles.th}><div style={styles.triangleB}></div>FY27<div style={styles.triangleA}></div></th>}
            </tr>
            {groupedArray.map((item: any) => {
                return <>
                    <tr>
                        <td colSpan={100} style={{ padding: "10px 0" }}>
                            <div className="f-name" style={styles.header}>{item.initiativeName}</div>
                        </td>
                    </tr>
                    {
                        item.roadmapItems.map((rItem: any) => <tr className="f-row">
                            <td style={styles.roadmapItem}>
                                {
                                    (() => {
                                        const ADOItemId = extractItemIdFromString(rItem.adolink);
                                        if (ADOItemId && ADOItemId.trim() !== '') {
                                            return (
                                                <a
                                                    href={`https://dev.azure.com/O365fasttrack/FastTrack/_workitems/edit/${ADOItemId}`}
                                                    target="_blank"
                                                >
                                                    {rItem.roadmapItemName}
                                                </a>
                                            );
                                        } else {
                                            return rItem.roadmapItemName;
                                        }
                                    })()
                                }
                            </td>
                            {columns.includes("FY25/Q1") && <td style={styles.targetRelease}>{rItem.fy25q1scope}</td>}
                            {columns.includes("FY25/Q2") && <td style={styles.targetRelease}>{rItem.fy25q2scope}</td>}
                            {columns.includes("FY25/Q3") && <td style={styles.targetRelease}>{rItem.fy25q3scope}</td>}
                            {columns.includes("FY25/Q4") && <td style={styles.targetRelease}>{rItem.fy25q4scope}</td>}
                            {columns.includes("FY26") && <td style={styles.targetRelease}>{rItem.fy26scope}</td>}
                            {columns.includes("FY27") && <td style={styles.targetRelease}>{rItem.fy27scope}</td>}
                        </tr>)
                    }
                </>
            })}
        </table>
    </div >);
};

export default ModernUI;
