import {
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Link,
    makeStyles
} from "@fluentui/react-components";
import { HEADER_VIDEO_LINK } from "../common/constants";

const useStyles = makeStyles({
    dialog: {
        width: '65%',
        maxWidth: '65%',
        maxHeight: '85%',
        textAlign: "center"
    },
});

const Header = () => {
    const styles = useStyles();

    return <header className="header">
        <h1>FastTrack Engineering Roadmap</h1>
        <h6>
        Get the latest updates from the FT Engineering team on the deployment automation, AI, and transformational investments that are helping simplify deployment journeys, accelerate usage, and drive revenue.
        </h6>
        <div>
            <Link href={HEADER_VIDEO_LINK} target="_blank">
                <Button appearance="primary" className="using-this-roadmap">
                    Using this roadmap
                </Button>
            </Link>
        </div>
    </header>
}

export default Header;
